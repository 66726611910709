<template>
  <div v-if="!hasRole('guest')" class="payment-buttons col-xs-12">
    <div class="row q-col-gutter-sm">
      <div
        v-if="requesterMustPayBySupplier"
        class="col-xs-12"
      >
        <q-btn
          color="primary"
          style="height: 55px"
          :disabled="disabled"
          @click="payBy('supplier')"
        >
          {{ $t('complete_booking') }}
        </q-btn>
      </div>
      <div
        v-if="requesterCanPayByCard"
        class="col-xs-12"
      >
        <q-btn
          style="height: 55px"
          color="primary"
          :disabled="disabled"
          @click="payBy('card')"
        >
          {{ $t('pay_by.card') }}
        </q-btn>
      </div>
      <div
        v-if="requesterCanPayByAccount && permissionDerivedTravelType === 'Business'"
        class="col-xs-12"
      >
        <q-btn
          style="height: 55px"
          color="primary"
          outline
          :disabled="disabled"
          @click="payBy('account')"
        >
          {{ $t('pay_by.account') }}
        </q-btn>
      </div>
      <div
        v-if="requesterCanPayByPaypal"
        class="col-xs-12"
        data-testid="m-paypal"
      >
        <m-paypal
          v-if="!disabled"
          :pence="totalAmount"
          @authorized="paypalAuthorized"
        />
      </div>
    </div>
  </div>
  <m-guest-blocker v-else />
</template>

<script>
import { MPaypal, MGuestBlocker } from 'components/index'
import authentication from 'mixins/authentication'
import loading from 'utils/loading'
import { mapGetters } from 'vuex'
export default {
  components: { MPaypal, MGuestBlocker },
  mixins: [authentication],
  props: {
    totalAmount: Number,
    requester: Object,
    bookingToken: String,
    reasonFor: String,
    journey: Object,
    labels: Array,
    supplierHandled: Boolean,
    travelType: String,
    isBikeReturn: Boolean,
    acceptance: Object,
    disable: Boolean
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    permissionDerivedTravelType () {
      return this.requester.permissions.includes('payments.account') ? this.travelType ? this.travelType : 'Business' : 'Private'
    },
    requesterCanPayByAccount () {
      if (this.supplierHandled) {
        return false
      }
      return this.requester.permissions.includes('payments.account')
    },
    requesterCanPayByCard () {
      if (this.supplierHandled) {
        return false
      }
      return this.requester.permissions.includes('payments.card')
    },
    requesterCanPayByPaypal () {
      if (this.supplierHandled) {
        return false
      }
      return !this.hasRole('backoffice') && this.requester.permissions.includes('payments.paypal')
    },
    requesterMustPayBySupplier () {
      if (this.supplierHandled) {
        return true
      }
      return false
    },
    paymentParameters () {
      return {
        token: this.bookingToken,
        requester: this.requester,
        journey: this.journey,
        reasonFor: this.reasonFor,
        labels: this.labels,
        travelType: this.travelType,
        isBikeReturn: this.isBikeReturn,
        acceptance: this.acceptance
      }
    },
    disabled () {
      if (this.disable) return true
      if (this.journey.name || this.journey.reference) return false
      return true
    }
  },
  methods: {
    payBy (paymentMethod) {
      loading.start({
        message: this.$t('loading.preparing.payment_method'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })

      this.$store.dispatch('payment/setParams', this.paymentParameters)

      this.$router.push({
        name: `booking-payment-${paymentMethod}`,
        params: this.paymentParameters
      })
    },
    paypalAuthorized (data, actions) {
      this.$store.dispatch('payment/setParams', this.paymentParameters)

      this.$router.push({
        name: 'booking-payment-paypal',
        params: {
          ...this.paymentParameters,
          actions,
          data
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.payment-buttons
  padding 16px
button
  width 100%
</style>
