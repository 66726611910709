<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code flex items-center justify-center" />
    <div class="column container">
      <div class="row">
        <q-card
          square
          class="bg-white"
        >
          <q-card-section :class="`bg-primary text-white text-uppercase`">
            {{ $tc('find.bus') }}
          </q-card-section>
          <q-separator />
          <q-card-section class="column">
            <div class="q-col-gutter-sm content-center relative-position" :class="$q.platform.is.mobile ? 'row' : ''">
              <div :class="$q.platform.is.desktop ? 'row' : 'col-10'">
                <div class="col-10 col-sm-5">
                  <m-location-search
                    ref="pickUp"
                    :value="parameters.origin"
                    :label="$t('going_from')"
                    :placeholder="$t('choose_location_or_address')"
                    no-shadow
                    keep-open
                    @selected="originSelect"
                  />
                </div>
                <div v-if="$q.platform.is.desktop" class="justify-center flex icon-wrap col-2">
                  <q-btn
                    flat
                    class="icon"
                    size="14px"
                    :icon="$q.platform.is.desktop ? 'fa fa-exchange' : 'fa fa-sort-alt'"
                    @click="swap"
                  />
                </div>
                <div class="col-10 col-sm-5">
                  <m-location-search
                    ref="pickUp"
                    :value="parameters.destination"
                    :label="$t('going_to')"
                    :placeholder="$t('choose_location_or_address')"
                    no-shadow
                    keep-open
                    @selected="destinationSelect"
                  />
                </div>
              </div>
              <div v-if="$q.platform.is.mobile" class="justify-center flex self-center icon-wrap col-2">
                <q-btn
                  flat
                  class="icon self-center"
                  size="14px"
                  :icon="$q.platform.is.desktop ? 'fa fa-exchange' : 'fa fa-sort-alt'"
                  @click="swap"
                />
              </div>
              <div class="row col-12 q-col-gutter-sm">
                <div class="col-6">
                  <date-field
                    v-model="parameters.depart"
                    :min="minDepart"
                    :label="$t('date.travel')"
                  />
                </div>
                <div class="col-6">
                  <time-field
                    v-model="parameters.depart"
                    :min="minDepart"
                    :minute-step="15"
                    :label="$t('time.from')"
                  />
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div
        class="row cta"
      >
        <q-btn
          :disabled="disabled"
          size="lg"
          color="primary"
          class="full-width"
          @click="submit"
        >
          {{ $tc('find.bus', 2) }}
        </q-btn>
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import authentication from 'mixins/authentication'
import { MLocationSearch, MUnifiedUsers, timeField, dateField } from 'components/'
import model from './model'
import { query } from 'api/places'
import date from 'utils/date-time'
import searchLocation from 'mixins/searchLocation'
const { addToDate } = date

export default {
  name: 'OndemandBus',
  components: {
    MUnifiedUsers,
    timeField,
    dateField,
    MLocationSearch
  },
  mixins: [authentication, searchLocation],
  data () {
    return {
      parameters: model()
    }
  },
  computed: {
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    },
    disabled () {
      const locationsSet = this.parameters.origin && this.parameters.destination
      return !(locationsSet && this.parameters.traveller && this.parameters.requester)
    },
    minDepart () {
      return addToDate(date.newDate(), { minutes: 5 })
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    '$route.query.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    '$route.query.destination': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.destination = val
        }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
  },
  activated () {
    this.$store.commit('ondemand/clear')
  },
  methods: {
    originSelect (value) {
      this.parameters.origin = value
    },
    destinationSelect (value) {
      this.parameters.destination = value
    },
    async autocomplete (terms) {
      const data = await query(terms)

      return data.map(location => {
        return {
          label: location.description,
          value: location.place_id,
          icon: 'place'
        }
      })
    },
    submit () {
      this.$store.dispatch('ondemand/stash', { parameters: this.parameters })
      this.$router.push({ name: 'ondemand-bus-routes' })
    },
    swap () {
      [this.parameters.destination, this.parameters.origin] = [
        this.parameters.origin,
        this.parameters.destination
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
.mobile
  .code
    display none
  .column.container
    margin 32px
  .cta
    background-color #fafafa
    padding 16px 8px
    position fixed
    left 0
    right 0
    bottom 0
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
.cta
  padding 8px
.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .7)
  overflow hidden
  background-image url('~assets/backgrounds/bus.jpg')
  background-position: cover;
  background-repeat: no-repeat;
  background-size: 115%;
  text-overflow: clip;

.column.container
  margin-top -64px

.q-card
  width 80vw
  max-width 600px

.center
  justify-content center
  display flex
</style>
