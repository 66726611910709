<template>
  <q-page>
    <div class="layout-padding">
      <div
        v-if="summary"
        class="row"
      >
        <div class="col-sm-12">
          <q-card class="bg-white">
            <q-card-section v-if="!$q.platform.is.mobile" class="bg-primary">
              {{ $t('booking.summary') }}
            </q-card-section>
            <q-card-section>
              <div
                v-if="summary.route"
                class="row"
                style="margin-top: 16px"
              >
                <bus-journey :journey="summary.route" />
              </div>
              <q-separator v-if="summary.route" class="hr" />
              <div class="ticket-type">
                <span class="ticket-name-cost"><strong> {{ stash.ticket.name }}</strong>{{ $n(stash.ticket.price/100, 'currency') }}<br></span>
                {{ stash.ticket.operator | toTitleCase }}
                <q-separator class="hr" />
              </div>
              <div v-if="totalPrice.net" class="row">
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('net_amount') }}</span>
                <span class="col-2 text-grey-9 text-right">{{ $n(totalPrice.net/100, 'currency') }}</span>
              </div>
              <div v-if="totalPrice.tax" class="row">
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('tax.vat') }}</span>
                <span class="col-2 text-grey-9 text-right">{{ $n(totalPrice.tax/100, 'currency') }}</span>
              </div>
              <q-separator class="hr" />
              <h6
                v-if="totalPrice.gross"
                class="row text-bold"
              >
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('total_cost') }}</span>
                <span class="col-2 text-grey-9 text-right">{{ $n(totalPrice.gross/100, 'currency') }}</span>
              </h6>
            </q-card-section>
          </q-card>
        </div>
        <div class="col-sm-12">
          <m-attach-to-booking-request
            :suggested="summary.suggested_journey_name || $tc('tickets.default')"
            :username="stash.parameters.traveller.value"
            :journey="journey"
            :show-travel-type="organisation && organisation.attributes.travel_policy.bus.enabled"
            @change="(val) => $store.dispatch('ondemand/journey', val)"
            @reasonChange="(val) => reason_for = val"
            @labelChange="(val) => labels = val"
            @travelTypeChange="(val) => travelType = val"
          />
        </div>
        <payment-buttons
          :total-amount="totalPrice.gross"
          :requester="stash.parameters.requester"
          :journey="journey"
          :reason-for="reason_for"
          :labels="labels"
          :booking-token="summary.token"
          :travel-type="(organisation && organisation.attributes.travel_policy.bus.enabled) || !organisation ? travelType : 'Private'"
        />
        <sub
          class="col-12 text-center text-faded"
          style="margin-top: 8px"
          v-html="$t('mobilleo_privacy_and_terms', { privacyURL: partner.privacyURL, termsURL: partner.termsURL })"
        />
      </div>
    </div>
  </q-page>
</template>
<script>
import { mapGetters } from 'vuex'
import store from 'store'
import { summary } from 'api/bus'
import { standalone } from 'api/tickets'
import loading from 'utils/loading'
import paymentButtons from 'pages/payments/payment-method-buttons'
import busJourney from './bus-journey'
import i18n from 'i18n'
import { MAttachToBookingRequest } from 'components/'
import { toTitleCase, handleErrors } from 'utils/utils'

export default {
  filters: {
    toTitleCase (str) {
      return toTitleCase(str)
    }
  },
  components: {
    busJourney,
    paymentButtons,
    MAttachToBookingRequest
  },
  data () {
    return {
      summary: null,
      reason_for: null,
      labels: null,
      travelType: null
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      journey: 'ondemand/journey',
      partner: 'partner',
      user: 'userLookup',
      organisation: 'organisation'
    }),
    totalPrice () {
      const { order_items } = this.summary
      const gross = order_items.reduce((total, item) => total + item.gross_amount.amount, 0)
      const net = order_items.reduce((total, item) => total + item.net_amount.amount, 0)
      const tax = order_items.reduce((total, item) => total + item.tax_amount.amount, 0)

      return { gross, net, tax }
    }
  },
  async beforeRouteEnter (to, from, next) {
    const stash = store.getters['ondemand/stash']
    const partner = store.getters.partner
    loading.start({
      message: i18n.t('loading.getting.ticket_summary'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data } = stash.selected
        ? await summary(stash.selected.route.token, { ticket_type_id: stash.ticket.id })
        : await standalone({ ticket_type_id: stash.ticket.id })

      next(vm => {
        vm.summary = {
          ...data,
          token: data.token || stash.selected.route.token,
          route: stash?.selected?.route
        }
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>
<style lang="stylus" scoped>
.q-card
  @media (max-width 768px)
    width 100vw
    margin 0

.layout-padding
  max-width 768px
button
  width 100%
span
  margin-top 16px
.hr
  margin-top 24px
  margin-bottom 10px

.item
  padding-left 0

.delivery-text
  margin-bottom 0

.adults
  padding-bottom 0
  min-height: 0

.adult-text
  font-size: 14px!important

.ticket-name-cost
  display flex
  justify-content space-between
</style>
