import http from './http'
// import { mockTicket } from 'mocks/tickets'

export function index () {
  return http.get(`/ticketing/bus`)
}

export function activate (id) {
  return http.post(`/ticketing/bus/activate/${id}`)
}

export function details (id) {
  // return Promise.resolve({ data: mockTicket })
  return http.get(`/ticketing/bus/${id}`)
}

export function standalone (params) {
  return http.post('/ticketing/bus/standalone', params)
}
