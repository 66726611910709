<template>
  <q-page>
    <div v-if="stash.buses" class="layout-padding">
      <div
        v-if="stash.buses.length > 0"
        class="row q-col-gutter-sm justify-center"
      >
        <div class="col-sm-12">
          <m-group-title :label="$t('bus.choose.route')" />
        </div>
        <div v-if="!ticketsAvailable && !hideNotify" class="col-sm-12" style="padding:16px">
          <q-card class="col-sm-12">
            <q-card-section horizontal>
              <q-card-section class="col-10">
                {{ $t('bus.tickets_unavailable_for_purchase') }}
              </q-card-section>

              <q-card-actions class="col-2" align="right">
                <q-btn color="primary" label="Dismiss" @click="() => { hideNotify = true }" />
              </q-card-actions>
            </q-card-section>
          </q-card>
        </div>
        <div
          v-for="(bus, i) in stash.buses"
          :key="bus.token"
          class="col-sm-12"
        >
          <bus-card
            :bus="bus"
            :tickets-available="ticketsAvailable"
            @click="setRoute"
          />
          <help-card v-if="i === 2 || i === stash.buses.length - 1" />
        </div>
      </div>

      <div v-else-if="stash.loading" class="loading">
        <q-spinner-dots size="60" />
      </div>

      <m-empty-state v-else icon="directions_bus">
        {{ $t('no_buses_found') }}
      </m-empty-state>
    </div>
    <q-footer v-if="ticketsAvailable && $q.platform.is.mobile" class="bg-white shadow-5 q-pa-sm q-py-md row justify-around">
      <button class="bg-primary buy-tickets" :disabled="!stash.selected" @click="buyTicket">
        {{ $t('bus.ticket.buy') }}
      </button>
    </q-footer>
  </q-page>
</template>
<script>
import busCard from './bus-card'
import { mapGetters } from 'vuex'
import { search } from 'api/bus'
import loading from 'utils/loading'
import store from 'store'
import date from 'utils/date-time'
import helpCard from '../../shared/help-card'
import i18n from 'i18n'
import { MGroupTitle } from 'components/'
import { handleErrors } from 'utils/utils'

export default {
  name: 'OndemandBusResults',
  components: {
    busCard,
    helpCard,
    MGroupTitle
  },
  data () {
    return {
      buses: null,
      hideNotify: false
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      partner: 'partner'
    }),
    ticketsAvailable () {
      return this.partner.slug === 'gohi'
    }
  },
  async beforeRouteEnter (to, from, next) {
    const { parameters } = store.getters['ondemand/stash']
    const partner = store.getters.partner
    loading.start({
      message: i18n.t('loading.searching.bus'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const params = {
        origin: parameters.origin.value,
        destination: parameters.destination.value,
        depart: date.toCivilDateTime(parameters.depart)
      }

      const { data } = await search(params)
      store.dispatch('ondemand/stash', {
        buses: data.outbound,
        selected: {
          route: data.outbound ? data.outbound[0] : null
        }
      })

      next(() => {
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  },
  methods: {
    setRoute (route) {
      const selected = this.stash.selected
      selected.route = route
      store.dispatch('ondemand/stash', { selected })
    },
    buyTicket () {
      this.$router.push({ name: 'public-transport-form' })
    }
  }
}
</script>
<style lang="stylus" scoped>
.layout-padding
  max-width 768px
  position relative

.q-page
  position relative

.vertical-rule
  max-width 1px !important
  margin-top 27px
  margin-bottom 7px
  background #c6c7c4

.horizontal-rule
  margin-left 8px
  margin-right 8px
  padding-bottom 5px
  border-bottom 1px solid #c6c7c4

.loading
  background rgba(244,244,244, .5)
  position absolute
  top 0
  right 150px
  bottom 0
  left 150px
  svg
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)

.buy-tickets
  border-radius 5px
  border none
  padding 5px
  width 100%
  height 50px
  text-transform uppercase
  font-weight 700
</style>
