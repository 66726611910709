<template>
  <q-card
    class="cursor-pointer card"
    :class="classes"
    square
    @click.native="selected"
  >
    <div class="row q-col-gutter-xs">
      <div
        class="col-12 q-pa-md"
      >
        <div class="row">
          <sup class="text-grey-7 col-10 q-my-auto"><strong class="text-blue-grey-6">{{ bus.origin.name }}</strong> to <strong class="text-blue-grey-6">{{ bus.destination.name }}</strong></sup>
        </div>
        <div class="row segment justify-around items-center text-dark">
          <strong class="col-xs-4 depart">{{ bus.depart.date | date }}</strong>
          <div class="col-xs-4">
            <div
              class="text-primary line"
            />
          </div>
          <strong class="col-xs-4 text-right arrive">{{ bus.arrive.date | date }}</strong>
        </div>
      </div>
    </div>
    <q-separator />
    <div class="row">
      <q-list class="col">
        <q-expansion-item
          group="bus-card-expansion-item"
          :label="$t('timeline')"
          :header-class="`text-grey-8 bg-white`"
          class="bg-white full-height"
          @click.stop
        >
          <q-timeline layout="loose" color="grey" class="q-px-sm">
            <q-timeline-entry
              v-for="(leg, index) in bus.segments"
              :key="index"
              :subtitle="leg.departure_time"
              :icon="legStyle(leg.type).icon"
              :side="leg.type === 'bus' ? 'right' : 'left'"
              :color="legStyle(leg.type).color"
              class="text-dark"
            >
              <template v-slot:title>
                <sup class="text-weight-regular">
                  <strong>{{ leg.line }}</strong>
                  <br>
                  {{ leg.from_point }}
                </sup>
              </template>
              <div class="text-caption text-faded">
                {{ $t('going_to_place', { place: leg.to_point }) }}
              </div>
              <q-tooltip>{{ legStyle(leg.type).label }}</q-tooltip>
            </q-timeline-entry>
            <q-timeline-entry
              :subtitle="bus.arrive.date | date"
              class="text-dark"
            >
              <template v-slot:title>
                <sup class="text-dark text-weight-regular">
                  {{ $t('arrive_at_time', {time: date(bus.arrive.date)}) }}
                </sup>
              </template>
            </q-timeline-entry>
          </q-timeline>
        </q-expansion-item>
      </q-list>
    </div>
  </q-card>
</template>
<script>
import date from 'utils/date-time'
import { mapGetters } from 'vuex'

export default {
  name: 'BusCard',
  filters: {
    date: value => date.toCivilTime(value)
  },
  props: {
    bus: null,
    highlight: {
      type: String,
      default: 'primary'
    },
    ticketsAvailable: Boolean
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    busLegs () {
      return this.bus.segments.filter((leg) => {
        return leg.type === 'bus'
      })
    },
    isSelected () {
      return this.stash.selected.route === this.bus || this.stash.selected.route === this.bus
    },
    classes () {
      return [this.isSelected ? `selected` : '']
    }
  },
  methods: {
    legStyle (type) {
      switch (type) {
      case 'bus':
        return {
          icon: 'directions_bus',
          color: `${this.highlight}-8`,
          label: 'Bus'
        }
      case 'taxi':
        return {
          icon: 'local_taxi',
          color: 'black',
          label: this.$t('content_type.taxi')
        }
      case 'foot':
        return {
          icon: 'directions_walk',
          color: 'green-8',
          label: this.$t('content_type.walking')
        }
      default:
        return {
          icon: 'far fa-question-circle',
          color: 'grey',
          label: `${this.$t('unknown_transport_type')}: ${type}`
        }
      }
    },
    selected () {
      this.$emit('click', this.bus)
    },
    date (dateValue) {
      return dateValue ? date.toCivilTime(dateValue) : null
    }
  }
}
</script>
<style lang="stylus" scoped>
.q-card
  min-height 120px
  position relative
  background-color #fff

.journey-info:hover
  text-decoration underline

.modal-body
  min-width 500px

.segment
  font-size 1.4em
  margin-top 8px
.lineName
  font-size 1.4em
.line
  position relative
  margin: 0 8px
  height: 1px
  background-color currentColor
  &:before,
  &:after
    content ""
    position absolute
    width 10px
    height 10px
    background currentColor
    border-radius 100%
    top -5px
  &:after
    right 0

.journey-leg
  position relative
  border-top 1px dashed lightgray
  padding-bottom 15px
  padding-top 15px

  h6
    margin-top 0

.journey-leg:first-child
  border-top 0

.point, .way
  background #1976d2

.point
  width 20px
  height 20px
  border-radius 100%
  top -5px

.way
  height 60px
  width 2px
  margin-left 9px

.to
  position absolute
  bottom 5px

sub
  display block

.spacer
  height 62px

.selected
  background-color light-info

</style>
